import React from 'react'
import Iframe from 'react-iframe'

function View360() {
  return (
    <div style={{overflowX: 'hidden', overflowY: 'hidden'}}>
      <Iframe src={'/output/index.html'}
        allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" frameborder= "0"
        id=""
        className="frame360"
        display='block'
        />
    </div>
  )
}

export default View360