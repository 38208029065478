import { useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import View360 from "./screens/View360";


const Navbar = lazy(() => import('./layouts/Navbar'));
const Home = lazy(() => import('./screens/Home'));
const Portfolio = lazy(() => import('./screens/Portfolio'));
const BlackOverlay = lazy(() => import('./layouts/BlackOverlay'));
const PortfolioDetails = lazy(() => import('./screens/PortfolioDetails'));
const InteriorServices = lazy(() => import('./screens/InteriorServices'));
const ExteriorServices = lazy(() => import('./screens/ExteriorServices'));


function App() {

  const [OpenNav, setOpenNav] = useState(false)

  return (
    <div className="App">

      <BrowserRouter>

        <Suspense fallback={<div className="loaderStyle"><MoonLoader size={100} color="#fff" /></div>}>

          <Navbar
            setOpenNav={setOpenNav}
            OpenNav={OpenNav}
          />

          <BlackOverlay />
          
          <Routes>
            <Route exact path="/" element={<Home setOpenNav={setOpenNav} />} />
            <Route exact path="/interior" element={<InteriorServices />} />
            <Route exact path="/exterior" element={<ExteriorServices />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/360" element={<View360 />} />
            <Route exact path="/portfolio/:id" element={<PortfolioDetails />} />
          </Routes>
        </Suspense>

      </BrowserRouter>
    </div>
  );
}

export default App;
